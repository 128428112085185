// https://fluentsite.z22.web.core.windows.net/quick-start
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { Navigate, Route, HashRouter as Router, Routes } from "react-router-dom";
import config from "../config";
import { AnalyticsProvider } from "../context/analytics/AnalyticsContext";
import { LessonProvider } from "../context/lesson/LessonContext";
import { useLanguage } from "../hooks/language/useLanguage";
import { TeamsFxContext } from "./Context";
import { Dashboard } from "./dashboard/Dashboard";
import { DebugMenu } from "./debug/DebugMenu";
import { UserIntake } from "./first-time-run/UserIntake";
import { Lesson } from "./lesson/Lesson";
import { CommonLoading } from "./loading/CommonLoading";
import { UserLocalizationInitializer } from "./user-localization/UserLocalizationInitializer";
import { AchievementsGallery } from "./achievements-gallery/AchievementsGallery";
import { ObtainLicense } from "./license-management/ObtainLicense";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export const App: React.FC = () => {
  const { loading, theme, themeString, teamsUserCredential, inTeams } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  const { initializeLanguage } = useLanguage();

  const queryClient = new QueryClient();
  const isReactQueryDebugToolsEnabled = config.developerDebugEnabled;
  const isDebugMenuEnabled = config.debugEnabled || config.demoEnabled;

  const initialize = async () => {
    await app.initialize();
    await initializeLanguage();

    // Hide the loading indicator.
    app.notifySuccess();
  };

  useEffect(() => {
    loading && initialize();
  }, [loading]);

  if (loading) {
    return (<CommonLoading />);
  }

  const appComponents = (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider>
          {!loading && <UserLocalizationInitializer />}
          <Router>
            {!loading && (
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/achievements" element={<AchievementsGallery />} />
                <Route path="/lesson/:lessonId" element={(<LessonProvider><Lesson /></LessonProvider>)} />
                <Route path="/intake" element={<UserIntake />} />
                <Route path="/unlicensed" element={<ObtainLicense />} />
                <Route path="*" element={<Navigate to={ inTeams ? "/intake" : "/unlicensed" } />}></Route>
              </Routes>
            )}
            {isDebugMenuEnabled && (<DebugMenu />)}
          </Router>
          {isReactQueryDebugToolsEnabled && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </AnalyticsProvider>
      </QueryClientProvider>
    </TeamsFxContext.Provider>
  );

  if (config.appInsightsEnabled) {
    const browserHistory = createBrowserHistory();
    const appInsightsReactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsightsConnectionString,
        enableAutoRouteTracking: true,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: browserHistory },
        }
      },
    });
    appInsights.addTelemetryInitializer((telemetryItem) => {
      telemetryItem.tags = telemetryItem.tags || {};
      telemetryItem.tags["ai.cloud.role"] = "Front-end";
    });

    appInsights.loadAppInsights();

    return (<AppInsightsErrorBoundary onError={_ => null} appInsights={appInsightsReactPlugin} >
      {appComponents}
    </AppInsightsErrorBoundary>);
  }

  return appComponents;
}
