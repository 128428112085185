import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import config from "../../config";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { CoachImage } from "../ui/CoachImage";
import { CommonButton } from "../ui/CommonButton";
import Panel from "../ui/Panel";
import './ObtainLicense.css';
import Markdown from "react-markdown";
import { useCoachConnection } from "../../hooks/coach-connector/useCoachConnection";
import { useLanguage } from "../../hooks/language/useLanguage";

export const ObtainLicense = () => {
    interface IUspProps {
        header: string;
        body: string;
    }

    const { t } = useTranslation();
    const { getCurrentLanguage } = useLanguage();

    const { trackPageView } = useAnalytics();
    const { sendWelcomeMessageToUserIfNotSentPreviously, sendUnlicensedMessageToUserIfNotSentPreviously } = useCoachConnection();

    useEffect(() => {
        const locale = getCurrentLanguage();

        const trackLicenseObtainedPageView = async () => {
            await trackPageView("/obtainLicense", "ObtainLicense");
        }

        const makeBotSendFirstMessages = async () => {
            await sendWelcomeMessageToUserIfNotSentPreviously(locale);
            await sendUnlicensedMessageToUserIfNotSentPreviously(locale);
        }

        Promise.all([
            trackLicenseObtainedPageView(),
            makeBotSendFirstMessages(),
        ]).catch(console.error);
    }, []);

    const goToAppSource = () => {
        window.open(config.appSourceUrl, '_blank');
    };

    const mailAddress = config.infoMail;
    const mailto = `mailto:${mailAddress}`;
    const webpageLink = config.websiteUrl;

    const usps = t('obtainLicense.usps', { returnObjects: true }) as IUspProps[];

    return (
        <Container className="obtain-license-container">
            <Panel className="obtain-license-content-panel align-items-center">
                <Container>
                    <Row className="obtain-license-content">
                        <h2>
                            { t('obtainLicense.header') }
                        </h2>

                        <div className="hero-logo">
                            <CoachImage src="dashboard/coach-icon.png" />
                        </div>

                        <Markdown children={t('obtainLicense.uspsPreamble')} />

                        <div className="dashboard-card-list row d-flex justify-content-left">
                            {
                                usps.map(usp => <>
                                    <div>
                                        <h3>{usp.header}</h3>
                                        <p>{usp.body}</p>
                                    </div>
                                </>)
                            }
                        </div>

                        <p className="call-to-action">
                            {isMobile ?
                                <Trans i18nKey="obtainLicense.callToAction" components={{
                                    appSourceLink: <></>
                                }}/>
                            : 
                                <Trans i18nKey="obtainLicense.callToAction" components={{
                                    appSourceLink: <CommonButton onClick={goToAppSource}>
                                        <></>
                                    </CommonButton>
                                }}/>
                            }
                        </p>

                        <p className="obtain-license-footer">
                            <Trans i18nKey="obtainLicense.footer" components={{
                                websiteLink: <a href={webpageLink} target="_blank"><></></a>,
                                mailLink: <a href={mailto} target="_blank"><></></a>
                            }} values = {{
                                address: mailAddress
                            }} />
                        </p>
                    </Row>
                </Container>
            </Panel>
        </Container>
    );
}
