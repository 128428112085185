import { Trans } from 'react-i18next';
import './AchievementsGalleryTenantStats.css';
import { Col, Container, Image, Row } from "react-bootstrap";
import { LessonCategory } from '../../hooks/lesson/LessonCategory';
import { CommonLoading } from '../loading/CommonLoading';
import { TenantCategoryStat } from './TenantCategoryStat';
import { useGetTenantStat } from '../../hooks/tenant-stat/useTenantStat';
import { isMobile } from 'react-device-detect';

export const AchievementsGalleryTenantStat: React.FC = () => {
    const { data: userTenantStat, isLoading, error } = useGetTenantStat();

    if (isLoading) {
        return (
            <Container className="fixed-bottom tenant-stat-container" fluid>
                <CommonLoading />
            </Container>
        )
    }

    if (error) {
        throw error;
    }

    const additionalClassNames = isMobile ? '' : 'row-cols-auto d-flex';

    return (
        <>
            <Container className="fixed-bottom tenant-stat-container d-flex justify-content-center align-items-center" fluid>
                <Row className={additionalClassNames + " align-items-center"}>
                    <Col>
                        <h2 className="org-stats-header">
                            <Trans i18nKey="tenantStats.header" />
                        </h2>
                    </Col>
                    {userTenantStat?.tenantStat?.categoryStats.map((categoryStat) => (
                        <Col key={categoryStat.categoryId}>
                            <TenantCategoryStat category={new LessonCategory(categoryStat.categoryId)} stat={categoryStat} />
                        </Col>
                    ))}
                </Row>
                <Row className={additionalClassNames + " align-items-center"}>
                    <Col>
                        <span className="side-note">
                            <Trans i18nKey="tenantStats.updatedDaily" />
                        </span>
                    </Col>
                </Row>
            </Container>
        </>
    )
};